import {
  Box,

  Typography,
  Stack
} from "@mui/material"
import React, { useState,useContext,useEffect } from "react"
import {GlobalStateContext} from "../../context/GlobalContextProvider";
import {gUtils} from "../utils/gutils";
import {gConfig} from "../../chatai/utils/config";

const CfpContentCount = ({ count, onClick, name, isShow = true, number }) => {

  return (
    <>
      {isShow && (
        <Box
          sx={{
            width: "220px",
            borderRadius: "6px",
            backgroundColor: "secondary.light",
            padding: "10px",
            flexDirection: "column",
            display: "flex",
            alignItems: "baseline",
            cursor: "pointer",
            "&:hover": {
              opacity: "0.6",
            },
          }}
          onClick={onClick}
        >
          <Typography
            sx={{
              fontFamily: "'Noto Sans JP', sans-serif",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "27.24px",
              letterSpacing: "0.06em",
            }}
          >
            {name}
          </Typography>

          <Box sx={{display: "flex"}}>
            <Typography
              sx={{
                fontFamily: "'Noto Sans JP', sans-serif",
                fontSize: "60px",
                fontWeight: 700,
                lineHeight: "81.72px",
                letterSpacing: "0.06em",
              }}
            >
              {count}
            </Typography>
          {/*  <Typography
              sx={{
                fontFamily: "'Noto Sans JP', sans-serif",
                fontSize: "20px",
                marginTop: "36px",
                lineHeight: "40.86px",
                letterSpacing: "0.06em",
              }}
            >
              /{number}
            </Typography>*/}
          </Box>
        </Box>
      )}
    </>
  )
}
export default function CfpContent({ cc, fc, pc, call, model,palmCount,faceCount }) {
  const { gStripe } = useContext(GlobalStateContext)

  const [cardCount,setCardCount]=useState(5)
  const [fpCount,setFpCount]=useState(5)
  const [pwCount,setPwCount]=useState(5)


  useEffect(()=>{
    if (gStripe.levelInfo){
       setCardCount(gStripe.levelInfo.cardCount)
       setFpCount(gStripe.levelInfo.fingerCount)
       setPwCount(gStripe.levelInfo.passwordCount)
    }

},[gStripe.levelInfo])

  return (
    <>
      <Box sx={{ ml: "20px", mt: "40px" }}>
        <Typography
          sx={{
            fontFamily: "'Noto Sans JP', sans-serif",
            fontSize: "23px",
            fontWeight: 600,
            lineHeight: "31px",
            letterSpacing: "0.06em",
            marginLeft: "14px"
          }}
        >
          登録内容
        </Typography>

        <Stack direction="row" spacing={2} sx={{ p: "18px 18px 18px 0" }}>
          <CfpContentCount
            count={cc}
            name="カード数"
            onClick={() => call(gConfig.touchType.card)}
            isShow={gUtils.isShowType(model,gConfig.touchType.card)}
            number={cardCount}
          />
          <CfpContentCount
            count={fc}
            name="指紋数"
            onClick={() => call(gConfig.touchType.finger)}
            isShow={gUtils.isShowType(model,gConfig.touchType.finger)}
            number={fpCount}
          />
          <CfpContentCount
            count={pc}
            name="暗証番号数"
            onClick={() => call(gConfig.touchType.password)}
            isShow={gUtils.isShowType(model,gConfig.touchType.password)}
            number={pwCount}
          />
            <CfpContentCount
                count={faceCount}
                name="人脸"
                onClick={() => call(gConfig.touchType.face)}
                isShow={gUtils.isShowType(model,gConfig.touchType.face)}
                number={pwCount}
            />
            <CfpContentCount
                count={palmCount}
                name="手掌"
                onClick={() => call(gConfig.touchType.palm)}
                isShow={gUtils.isShowType(model,gConfig.touchType.palm)}
                number={pwCount}
            />
        </Stack>
      </Box>
    </>
  )
}
