import Layout from "../../layouts"
import React, { useState, useContext, useEffect } from "react"
import { GlobalStateContext } from "../../context/GlobalContextProvider"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  Typography,
  FormLabel,
  TextField,
} from "@mui/material"
import { Link, navigate } from "gatsby"
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft"
import EditRoundedIcon from "@mui/icons-material/EditRounded"
import Hider from "../../components/utils/Hider"
import {
  CfpCheckMachine,
  CfpDgBindMember,
} from "../../components/cfp/cfpdialogbindmember"

import CfpContent from "../../components/cfp/cfpcontent"
import Cfptb from "../../components/cfp/cfptb"
import { cfpColumns } from "../../components/utils/cfpcolumns"
import { cfputils } from "../../components/utils/cfputils"
import {gConfig} from "../../chatai/utils/config";


export default function CDetails({ location }) {
  const { state = {} } = location
  const { gCfpDevice, gIotCfp, gCfp, gCfpCards, gCfpfp } =
    useContext(GlobalStateContext)
  const { data } = state || {}

  const [edit, setEdit] = useState(false)
  const [mdetails, setmdetails] = useState({})
  const [editInfo, setEditInfo] = useState({ ...data })
  const [openSsm, setOpenSsm] = useState(false)
  const [mtitle, setMtitle] = useState(false)
  const [model, setModel] = useState("")
  const [items, setItems] = useState([])
  const [cards, setCards] = useState([])
  const [passwords, setPasswords] = useState([])
  const title = {width: "180px", fontWeight: "bold",}
  const infoItem = { display: "flex", alignItems: "center" }
  useEffect(() => {
    if (!data){
      navigate("/")
    }else {
      setItems(
          gCfp.cfpSsmDevices.filter((item) =>
              gCfpDevice.touchDevices.some((ite) => ite.mid.toUpperCase() === item.deviceUUID.toUpperCase())
          )
      )
    }

  }, [gCfpDevice.touchDevices])
  const { deviceUUID: touchId = "" } = data || {}
  useEffect(() => {
    if (!data){
      navigate("/")
    }else {
 /*     let m = ""
      if (state.data.deviceModel === "ssm_touch") {
        m = "cf"
      } else if (state.data.deviceModel === "ssm_touch_pro") {
        m = "cfp"
      }else if (state.data.deviceModel==='sesame_face'){
        m=
      }*/
      setModel(state.data.deviceModel)
      setmdetails({
        デバイスUUID: touchId,
        /*   電池残量: `${gIotCfp.deviceShadow?.bp || "100"}%`,*/
        /*   電池残量: `${gIotCfp.deviceShadow?.bp || "100"}%`,*/
        電池残量: state.data.battery,
        モデル: state.data.deviceModel,
        登録日時: cfputils.timestampToTime(state.data.registerBizTime),
      })
    }

  }, [gIotCfp.deviceShadow])

  useEffect(() => {
    setMtitle(
      gCfp.cfpDevices.find((item) => item.deviceUUID === touchId)?.deviceName ??
        ""
    )
  }, [gCfp.cfpDevices])
  useEffect(() => {
    if (data){
      setMtitle(state.data.deviceName)
      gCfp.setTouchId(state.data.deviceUUID)
      gCfpDevice.getTouchDevice(touchId)


      // gCfpCards.getDeviceCards(touchId)
      gCfpfp.getFp("f", touchId)
      //gCfpfp.getFp("p", touchId)
    }

  }, [state])

  useEffect(() => {


    setCards(gCfpCards.allCards.filter(item => item.uuids.some(ite => ite === touchId)));
    setPasswords(gCfpfp.devicePasswords.filter(item => item.deviceID===touchId));

  }, [gCfpCards.allCards]);
  const [isBindMember, setisBindMember] = useState(false)

  const handleClose = () => {
    setisBindMember(false)
  }
  return (
    <Layout location={location}>
      <Card>
        <Box>
          <Hider show={edit}>
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <IconButton
                size="small"
                sx={{ mt: "3px" }}
                onClick={() => {
                  window.history.back()
                }}
              >
                <KeyboardArrowLeftIcon />
              </IconButton>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormLabel
                  sx={{ color: "black", mr: "43px", fontWeight: "bold" }}
                  required
                >
                  デバイス名
                </FormLabel>
                <TextField
                  sx={{ minWidth: "350px" }}
                  size="small"
                  required
                  value={editInfo.deviceName}
                  onChange={(e) => {
                    setEditInfo({
                      ...editInfo,
                      deviceName: e.target.value,
                    })
                    //  state.title = e.target.value
                  }}
                />
              </Box>
              <Box
                sx={{ mt: "10px", display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  sx={{ mr: 1 }}
                  size="small"
                  onClick={() => {
                    setEdit(false)
                    setEditInfo(editInfo)
                  }}
                >
                  キャンセル
                </Button>
                <Button
                  disableElevation
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    console.log("修改设备名称")
                    gCfp.cfpModifyCfpDevicesName(
                      touchId,
                      editInfo,
                      setEdit(false)
                    )
                  }}
                >
                  確定
                </Button>
              </Box>
            </Box>
            <Box>
              <CardHeader
                title={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton size="small" component={Link} to="/cfp">
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                    <Typography variant="h2" sx={{ ml: "9px" }}>
                      {mtitle}
                    </Typography>
                    <IconButton
                      sx={{ ml: "2px" }}
                      size="small"
                      onClick={() => {
                        setEdit(true)
                      }}
                    >
                      <EditRoundedIcon
                        fontSize="inherit"
                        sx={{ color: "primary.main", fontSize: "14px" }}
                      />
                    </IconButton>
                  </Box>
                }
              />

              <CardContent>
                <List
                  disablePadding
                  sx={{
                    "> .css-1samsxy-MuiListItem-root": {
                      padding: "0px",
                    },
                  }}
                >
                  {Object.entries(mdetails).map(([key, value], index) => (
                    <ListItem key={index} sx={{ ...infoItem }}>
                      <Typography sx={{ ...title }}>{key}</Typography>
                      {value}
                      {key === "社员" && (
                        <Button
                          sx={{ marginLeft: "50px" }}
                          variant="outlined"
                          onClick={() => {
                            console.log("打开")
                            setisBindMember(true)
                          }}
                        >
                          绑定社员
                        </Button>
                      )}
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Box>
          </Hider>
          <Cfptb
            isDel={false}
            isCsv={false}
            isBind={false}
            isLine={false}
            isAdd={false}
            isPagination={false}
            adjustToolBar={true}
            isSmallHeight={true}
            data={items}
            text={"連携済みセサミ"}
            selectableRows={"none"}
            columns={cfpColumns.bindSesame()}
            callAdd={() => {
              setOpenSsm(true)
            }}
          />

          <CfpCheckMachine
            deviceList={gCfpDevice.ssmDevices}
            mOpen={openSsm}
            handleClose={() => {
              setOpenSsm(false)
            }}
            callChoose={(mitems) => {
              setOpenSsm(false)
              if (mitems === null || mitems.length === 0) {
                return
              }
              let ns = mitems.map((item) => {
                return {
                  deviceUUID: item.deviceUUID,
                  secretKey: item.secretKey,
                  deviceName: item.deviceName,
                  deviceModel: item.deviceModel,
                }
              })


              gCfpDevice.addBindDevice(touchId, ns)
            }}
            title={"選擇與此刷卡機要連接的SESAME"}
          />
          <CfpContent
            model={model}
            faceCount={gCfpfp.deviceFace.length||0}
            palmCount={gCfpfp.devicePalm.length||0}
            cc={cards.length || 0}
            fc={gCfpfp.deviceFingers.length || 0}
            pc={passwords.length || 0}
            call={(type) => {
              // console.log('click', type)
              let mstate = {
                title: mtitle,
                uuid: touchId,
              }
              //     state: { title: state.title, type: type ,uuid:state.data.deviceUUID},
              let path = ""
              if (type === gConfig.touchType.card) {
                path = "/cfp/cards"
              } else if (type === gConfig.touchType.password) {
                path = "/cfp/passwords"
              } else if (type === gConfig.touchType.finger) {
                path = "/cfp/fingers"
              }else if (type === gConfig.touchType.face) {
                path = "/cfp/face"
              }else if (type === gConfig.touchType.palm) {
                path = "/cfp/palm"
              }
              navigate(path, { state: mstate })
            }}
          />
        </Box>
      </Card>
      <CfpDgBindMember mOpen={isBindMember} handleClose={handleClose} />
    </Layout>
  )
}
